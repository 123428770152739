import parse, { HTMLReactParserOptions, Element, domToReact, DOMNode } from 'html-react-parser';
import Link from 'next/link';
import Script from 'next/script';

type ConfigOptions = {
  customScript?: {
    id: string;
    category: string;
    service: string;
  };
};

// import * as DOMPurify from 'isomorphic-dompurify';
// if we need to sanitize the HTML we can just run DOMPurify.sanitize(html) below
// but leaving it for now as it may not be necessary and might have a performance cost.

/**
 * @param html The HTML to be parsed into React.
 * @returns React elements representing the input HTML, with <a> elements replaced with NextJS <Link>.
 */
export default function htmlToReact(html: string, configOptions: ConfigOptions = {}) {
  const options: HTMLReactParserOptions = {
    replace(domNode) {
      if (domNode instanceof Element && domNode.name === 'a' && domNode.attribs.href) {
        return (
          <Link
            href={domNode.attribs.href}
            className={domNode.attribs.class}
            target={domNode.attribs.target}
          >
            {!!domNode.children.length
              ? domToReact(domNode.children as DOMNode[])
              : (domNode.children[0] as unknown as Text)?.data}
          </Link>
        );
      }
      if (domNode instanceof Element && domNode.name === 'script') {
        return (
          <Script
            {...domNode.attribs}
            strategy="lazyOnload"
            async
            // If custom script, add data attributes to connect to cookie consent
            {...(configOptions.customScript && {
              type: 'text/plain',
              'data-category': configOptions.customScript.category,
              'data-service': configOptions.customScript.service,
              id: configOptions.customScript.id,
            })}
          >
            {(domNode.children[0] as unknown as Text)?.data}
          </Script>
        );
      }
    },
  };

  return parse(html, options);
}
