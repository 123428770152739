import { selectActiveProfileView, setActiveProfileView } from '@/store/theme-slice';
import { useSelector, useDispatch } from 'react-redux';

export default function useUserProfile() {
  const dispatch = useDispatch();
  const activeProfileView = useSelector(selectActiveProfileView);

  function openProfile() {
    dispatch(setActiveProfileView('sign-in'));
  }

  function closeProfile() {
    dispatch(setActiveProfileView(null));
  }

  function setProfileView(view: string | null) {
    dispatch(setActiveProfileView(view));
  }

  return { activeProfileView, openProfile, closeProfile, setProfileView };
}
