'use client';

import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

// @ts-ignore - argument types for dynamic appear to be treat the options as the first argument instead of import callback
const CookieBanner = dynamic(() => import('./cookie-banner.component.tsx'), { ssr: false });

import 'vanilla-cookieconsent/dist/cookieconsent.css';

export default function LazyCookieBanner() {
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) setLoaded(true);
  }, [inView]);

  return (
    <div className="h-0 w-0 opacity-0" ref={ref}>
      {loaded && <CookieBanner />}
    </div>
  );
}
